

















































import { mapGetters } from 'vuex'
import Vue from 'vue'
import i18n, { changeLang } from '@/i18n'

export default Vue.extend({
  name: 'AccessDeniedSign',

  props: {
    logoProp: String
  },
  components: {
  },

  data () {
    return {
      logo: undefined as String | undefined,
      name: undefined as String | undefined
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      company: 'company',
    }),
    query (): any {
      return this.$route.query
    },
  },
  async mounted () {
    if (this.company && this.company.metadata && this.company.metadata.logo) {
      this.logo = this.company.metadata.logo
    }
    if (this.account) {
      this.name = Vue.filter('fullNameOfUser')(this.account)
    }
    if (this.query.lang) {
      i18n.locale = this.query.lang
      changeLang(this.query.lang)
    }
    if (this.query.logo) {
      this.logo = this.query.logo
    }
    if (this.query.name) {
      this.name = this.query.name
    }
  },
  methods: {

  },
})
